import $ from "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";

/* this declaration in /clientapp/types/static.d.ts isn't picked up, so putting it here */
declare global {
  interface Window {
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}

// need jQuery on the window object for jquery validation
window.jQuery = $;

export default class Forms {
  static init() {
    // on submit
    $("form").on("submit", (e) => {
      try {
        const $this = $(e.target);
        if ($this.valid()) {
          // disable button on submit
          const $submitBtn = $this.find("[type=submit]");
          $submitBtn.attr("disabled", "disabled");
          $submitBtn.data("originalhtml", $submitBtn.html());
          $submitBtn.html('<i class="fas fa-circle-notch fa-spin"></i>');
        }
      } catch (e) {
        console.error(e);
      }
    });
    this.addExtraValidation();
  }

  static resetSubmitButtons() {
    $("form [type='submit']").each((index, element) => {
      const $submitBtn = $(element);
      $submitBtn.html($submitBtn.data("originalhtml"));
      $submitBtn.removeAttr("disabled");
    });
  }

  private static addExtraValidation() {
    // mustbetrue
    $.validator.unobtrusive.adapters.addBool("mustbetrue", "required");

    // requiredif
    $.validator.addMethod("requiredif", (value, element, params) => {
      const $params = $(params);
      if (!$params) return true;
      if (!$params.val()) return true;
      if (`${$params.val()}`.length === 0) {
        return true;
      } else if (value.length === 0) {
        return false;
      }
      return true;
    });

    $.validator.unobtrusive.adapters.add("requiredif", ["otherpropertyid"], function (options: any) {
      options.rules["requiredif"] = "#" + options.params.otherpropertyid;
      options.messages["requiredif"] = options.message;
    });

    // daterange
    $.validator.addMethod("daterange", (value, element, params) => {
      // value is of type string
      // value is date formatted as 'yyyy-MM-dd'
      // same for params.min and params.max
      if (value == null || value == "") {
        return true;
      }
      return params.min <= value && value <= params.max;
    });

    $.validator.unobtrusive.adapters.add("daterange", ["min", "max"], function (options: any) {
      // console.log("daterange - add adapter", options);
      options.rules["daterange"] = options.params;
      options.messages["daterange"] = options.message;
    });
  }
}
