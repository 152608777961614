import axios from "axios";
import $ from "jquery";
import $$ from "./utils/double-dollar";

import * as axiosX from "./http/axios-extensions";
import Forms from "./utils/forms";
import {Tooltip} from "bootstrap";
import ProductPricing from "./product-pricing";

export default class SearchPage {
  private searchBarSelector: string;
  private searchResultsSelector: string;
  private pagerSelector: string;
  private otherUpdatePartSelectors: string[] | undefined;

  private contextHeaders: any = {};
  private pageMap: any = {};

  public constructor(
    searchBarSelector: string,
    searchResultsSelector: string,
    pagerSelector: string,
    otherUpdatePartSelectors?: Array<string>
  ) {
    this.searchBarSelector = searchBarSelector;
    this.searchResultsSelector = searchResultsSelector;
    this.pagerSelector = pagerSelector;
    this.otherUpdatePartSelectors = otherUpdatePartSelectors;
  }

  public init = () => {
    this.setContextHeaders($("body"));

    // only set hidden input fields directly when not on search page:
    $(`${this.searchBarSelector}:not(.js-on-search-page)`).on("click", ".nt-btn-search-toggle", (event) => {
      const $toggle = $(event.currentTarget);
      let hasClassBeforeThisEvent = $toggle.hasClass("active");
      let isActive = hasClassBeforeThisEvent ? 0 : 1;
      let fieldname = $toggle.data("ifActiveSetFieldTo-1");
      let $field = $(`input[name='${fieldname}'`);
      $field.val(isActive);
      return true;
    });

    // event handler for navigating back in (client-side) history
    // this will make sure that the data for the URL is loaded
    window.addEventListener("popstate", (): void => {
      this.loadPage(document.location.href, false).then((response: any) => {
        const $html = $("<div/>").append(response.data);
        this.replaceOnPage($html, this.searchBarSelector);
      });
    });

    $(this.pagerSelector).on("click", "a.page-link", (event) => {
      event.preventDefault();
      // take currentTarget, is always the a tag (even when clicking on icon)
      let url = $(event.currentTarget).attr("href");
      if (typeof url === "undefined") {
        return false;
      }
      this.loadPage(url, true);
      return true;
    });

    // only send search query from AJAX when on search page:
    $(`${this.searchBarSelector}.js-on-search-page`).on("submit", "form", (event) => {
      const form$ = $(event.target);
      event.preventDefault();
      // set hidden input fields "f" and "s"
      $(".nt-btn-search-toggle").each((index, element) => {
        const $toggle = $(element);
        let hasActiveClass = $toggle.hasClass("active");
        let isActive = hasActiveClass ? 1 : 0;
        let fieldname = $toggle.data("ifActiveSetFieldTo-1");
        let $field = $(`input[name='${fieldname}'`);
        $field.val(isActive);
      });
      const url = form$.attr("action") + "?" + form$.serialize();
      this.loadPage(url, true);
      return true;
    });
  };

  public loadPage = (url: string, push: boolean) => {
    const params = new URLSearchParams(url);
    let p = params.get("p");
    let page = parseInt("" + p);

    const posting = axios.post(url, this.pageMap[page ?? 1], {headers: this.contextHeaders});

    posting.then((response: any) => {
      this.processResponseData(response.data);
      if (push) {
        // add to navigation history
        history.pushState(null, document.title, url);
      }
    });

    posting.catch(axiosX.defaultCatch);

    posting.finally(() => {
        Forms.resetSubmitButtons();
        ProductPricing.init();
    });

    // no need to set title with X-AJAXPAGETITLE header because title remains the same ??

    // return promise
    return posting;
  };

  private processResponseData(data: string | Object) {
    if (typeof data === "string") {
      // data = HTML page
      const data_ = data as string;
      const $html = $("<div/>").append(data_);
      this.replaceOnPage($html, this.pagerSelector);
      $(this.searchResultsSelector).fadeOut({
        duration: "fast",
        done: () => {
          this.replaceOnPage($html, this.searchResultsSelector);
          $(this.searchResultsSelector).fadeIn({
            duration: "fast",
            done: () => {
              //ProductPricing.init();
              $$(`[data-toggle="tooltip"]`, el => new Tooltip(el))
            },
          });
        },
      });
      this.otherUpdatePartSelectors?.forEach((value, index, array) => {
        this.replaceOnPage($html, value);
      });
      this.setContextHeaders($html);
    } else if (typeof data === "object") {
      // there is only one search result => open product detail with returned redirectUrl
      const data_ = data as any;
      document.location = data_.redirectUrl;
    }
  }

  private setContextHeaders = ($html: JQuery<HTMLElement>) => {
    this.contextHeaders = {};
    $html.find("input.js-set-http-header").each((index, element) => {
      let $element = $(element);
      let header = $element.data("forHttpHeader");
      if (!!header) {
        this.contextHeaders[header] = $element.val();
        if (header === "X-ProductSearch-Results") {
          this.pageMap = JSON.parse("" + $element.val());
        }
      }
    });
  };

  private replaceOnPage = ($html: JQuery<HTMLElement>, selector: string) => {
    const $newResults = $html.find(selector);
    // swap results on page with those from the XHR request:
    $(selector).html($newResults.html());
  };
}
