import $ from "jquery";
import axios from "axios";

import * as axiosX from "../http/axios-extensions";
import Forms from "../utils/forms";
import * as Toaster from "../utils/toaster";
import * as HttpProblemDetails from "../http/http-problem-details";
import type {Guid} from "./types";
import MiniBasket from "./mini-basket";

type SelectListItem = {
  text: string;
  value: string;
};

enum AddToBasketTemplateStatus {
  AddedNewLine = "AddedNewLine",
  UpdatedExistingLine = "UpdatedExistingLine",
}

type LineResponse = {
  productName: string;
  quantity: number;
  status: AddToBasketTemplateStatus;
};

type AddToBasketTemplateResponse = {
  lineResponses: LineResponse[];
};

function isAddToBasketTemplateResponse(x: any): x is AddToBasketTemplateResponse {
  return typeof x === "object" && !!x.lineResponses && Array.isArray(x.lineResponses);
}

class BasketTemplateDelete {
  private static readonly modalSelector = "#delete-basket-template-modal";
  private static readonly modalBasketNameSelector = ".js-basket-template-to-delete--name";
  private static readonly modalBasketUidSelector = ".js-basket-template-to-delete--bid";

  static init() {
    $(document).on("click", ".js-confirm-delete-basket-template", (e) => {
      const $modal = $(this.modalSelector);
      const basketUniqueId = $modal.find(BasketTemplateDelete.modalBasketUidSelector).val();
      axios
        .post("/basket-templates/delete/" + basketUniqueId)
        .then(() => {
          $(`.js-basket-template[data-bid="${basketUniqueId}"]`).remove();
          $(`#basket_${basketUniqueId}`).remove();
          $modal.modal("hide");
        })
        .catch(axiosX.defaultCatch);
    });
    $(document).on("click", ".js-request-delete-basket-template", (e) => {
      const $clicked = $(e.currentTarget);
      const modalSelector = $clicked.data("bsTarget");
      const $modal = $(modalSelector);
      fillDeleteConfirmationModal($modal, $clicked.data("bid"), $clicked.data("basketTemplateName"));
    });

    function fillDeleteConfirmationModal($modal: JQuery<HTMLElement>, basketUid: Guid, templateName: string) {
      $modal.find(BasketTemplateDelete.modalBasketNameSelector).html(templateName);
      $modal.find(BasketTemplateDelete.modalBasketUidSelector).val(basketUid);
    }

    $(this.modalSelector).on("hidden.bs.modal", function (e) {
      const $modal = $(e.target);
      $modal.find(BasketTemplateDelete.modalBasketNameSelector).html("");
      $modal.find(BasketTemplateDelete.modalBasketUidSelector).val("");
    });
  }
}

class BasketTemplateAddToShoppingBasket {
  static init() {
    $(document).on("click", ".js-add-basket-template-to-shopping-basket", (e) => {
      const $clicked = $(e.currentTarget);
      const basketUniqueId = $clicked.data("bid");
      let posting = axios.post("/basket-templates/add-to-basket/" + basketUniqueId);
      posting.then(() => {
        Toaster.pop({
          toastTemplateSelector: ".nt-toast--templateaddedtobasket.nt-toast--success",
        });
        MiniBasket.update();
      });
      posting.catch(axiosX.defaultCatch);
    });
  }
}

export default class BasketTemplateInteractions {
  private static readonly addToBasketModalId = "add-to-basket-template-modal";

  static init() {
    BasketTemplateDelete.init();
    BasketTemplateAddToShoppingBasket.init();
    $(document).on("click", ".js-open-add-to-basket-template-modal", (e) => {
      const $clicked = $(e.currentTarget);
      const modalSelector = $clicked.data("bsTarget");
      const $modal = $(modalSelector);
      this.setCopyFromBasketValues($clicked, $modal);
      this.setLinesValues($clicked, $modal);
      this.getBasketTemplateList();
    });
    $(document).on("submit", `#${BasketTemplateInteractions.addToBasketModalId} form`, (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.submitModalForm($(e.target));
    });
    $(`#${BasketTemplateInteractions.addToBasketModalId}`).on("hidden.bs.modal", function (e) {
      const $modal = $(e.target);
      $modal.find("input").val("");
      $modal.find(".js-existing-baskettemplates-list option").remove();
      $modal.find(".js-btn-add-to-existing-baskettemplate").prop("disabled", true);
      const $form = $modal.find("form");
      $form.find("input").removeClass("is-invalid");
      $form.find(".invalid-feedback").text();
    });
  }

  private static submitModalForm($form: JQuery<HTMLElement>) {
    const url = $form.attr("action") + "?" + $form.serialize();
    const $modal = $form.parents(".modal");
    axios
      .post(url)
      .then((response: any) => {
        let addedToBasket = response.data;
        if (isAddToBasketTemplateResponse(addedToBasket)) {
          addedToBasket.lineResponses.forEach((lineResponse) => {
            if (lineResponse.status === AddToBasketTemplateStatus.UpdatedExistingLine) {
              Toaster.pop({
                toastTemplateSelector: ".nt-toast--updated-baskettemplate-line.nt-toast--success",
                extraBodyMessage: `${lineResponse.productName} (# ${lineResponse.quantity})`,
              });
            }
          });
        }
        $modal.modal("hide");
      })
      .catch((error: any) => {
        if (error.response) {
          const details = HttpProblemDetails.asHttpProblemDetails(error.response.data, JSON.stringify(error.response));
          if (details.status < 500) {
            $form.find(".invalid-feedback").text(details.detail);
            $form.find("input, select").addClass("is-invalid");
            return;
          }
        }
        axiosX.defaultCatch(error);
      })
      .finally(Forms.resetSubmitButtons);
  }

  /**
   * Set parameters when adding to a basket template from a shopping basket.
   * @param $clicked
   * @param $modal
   */
  private static setCopyFromBasketValues($clicked: JQuery<HTMLElement>, $modal: JQuery<HTMLElement>) {
    const basketUid = $clicked.data("basketUid");
    // set this value on hidden input field of the modal:
    $modal.find(".js-add-to-baskettemplate--basket-uid").val(basketUid);
  }

  /**
   * Set parameters when adding one line to a basket template.
   * @param $clicked
   * @param $modal
   */
  private static setLinesValues($clicked: JQuery<HTMLElement>, $modal: JQuery<HTMLElement>) {
    const $form = $clicked.parents(".js-product").find("form.nt-add-to-basket");
    const quantityValue = $(`[name="quantity"]`, $form).val() as string;
    if (quantityValue === "") {
      return;
    }

    const encryptedProductId = $form.data("pid");
    const quantity = parseInt(quantityValue);

    // set these values on hidden input fields of the modal:
    $modal.find(".js-add-to-baskettemplate--pid").val(encryptedProductId);
    $modal.find(".js-add-to-baskettemplate--quantity").val(quantity);
  }

  private static getBasketTemplateList() {
    axios.get("/basket-templates").then(this.fillExistingBasketTemplatesList).catch(axiosX.defaultCatch);
  }

  private static fillExistingBasketTemplatesList(response: any) {
    let listItems: SelectListItem[] = response.data as SelectListItem[];
    var $dropdown = $(".js-existing-baskettemplates-list");
    $dropdown.find("option").remove();
    $.each(listItems, (index, listItem) => {
      $dropdown.append($("<option />").val(listItem.value).text(listItem.text));
    });
    if (listItems.length > 0) {
      $(".js-btn-add-to-existing-baskettemplate").prop("disabled", false);
    }
  }
}
