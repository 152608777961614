import axios from "axios";
import $ from "jquery";

import * as axiosX from "./http/axios-extensions";

type ToggleFavoriteRequestDto = {
  encryptedProductId?: string;
};

export default class ProductFavoritizer {
  static init() {
    $(document).on("click", ".js-btn-toggle-favorite", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.toggleFavoriteState(e.currentTarget);
    });
  }

  private static toggleFavoriteState(element: HTMLElement) {
    const $element = $(element);
    const request = {encryptedProductId: $element.data("pid")};
    const isFavorited = $element.data("isFavorited");
    if (isFavorited === true) {
      this.removeFromFavorites(request, () => {
        $element.data("isFavorited", false);
        $element.children(".js-show-when-favorited").hide();
        $element.children(".js-show-when-not-favorited").show();
      });
    } else if (isFavorited === false) {
      this.addToFavorites(request, () => {
        $element.data("isFavorited", true);
        $element.children(".js-show-when-favorited").show();
        $element.children(".js-show-when-not-favorited").hide();
      });
    } else {
      console.error("unable to read current favorite state");
    }
  }

  private static addToFavorites(request: ToggleFavoriteRequestDto, successCallback: () => void) {
    axios.post("/product-favorites/add-to-favorites", request).then(successCallback).catch(axiosX.defaultCatch);
  }

  private static removeFromFavorites(request: ToggleFavoriteRequestDto, successCallback: () => void) {
    axios.post("/product-favorites/remove-from-favorites", request).then(successCallback).catch(axiosX.defaultCatch);
  }
}
