import $ from "jquery";

export default class MiniBasket {
  public static update() {
    const posting = $.get("/basket/summary");
    posting.done((data: any) => {
      let element = document.getElementById("mini-basket__amount");
      if (element) {
        element.innerHTML = data.totalAmountFormatted;
      }
      element = document.getElementById("mini-basket__linecount");
      if (element) {
        element.innerHTML = data.lineCountFormatted;
      }
    });
  }
}
