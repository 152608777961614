import $ from "jquery";
import axios from "axios";

import * as axiosX from "../http/axios-extensions";
import QuantitySanitizer from "./quantity-sanitizer";

import type {
  BasketAddRequestDto,
  BasketAddResponseDto,
  BasketLineQuantityUpdateResponse,
  BasketUpdateLineRequestDto,
  Guid,
} from "./types";
import {
  isAddedToBasketDto,
  isChangeLineQuantityResponse,
  isInsufficientStockDetectedDtoOfTypeBasketUpdateLineRequestDto,
} from "./type-guards";
import AddToBasketComponent from "./add-to-basket";
import OpenInsuffienctStockModalFactory from "./insufficient-stock-modal";

type BasketLineRemoveRequestDto = {
  basketLineGroupGuid: Guid;
  basketUniqueId?: Guid;
};

export default class BasketComponent {
  public readonly cssClassQuantityInput = "input.nt-update-basketline-quantity-onchange";
  public readonly quantitySanitizer = new QuantitySanitizer();
  public readonly elementSelector: string;

  constructor(elementSelector: string) {
    this.elementSelector = elementSelector;
    $(elementSelector).on("change", this.cssClassQuantityInput, (e) => {
      // TODO add debounce for when we update using arrows of the input type=number control
      e.preventDefault();
      e.stopImmediatePropagation();
      this.changeBasketLineQuantity(e.target);
    });
    $(elementSelector).on("click", ".btn.nt-remove-basketline", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.removeBasketLine(e.currentTarget);
    });
    $(document).on("basketLine:conflictResolved", (e, pid, bid) => {
      this.handleBasketLineConflictResolved(pid, bid);
    });
    $(elementSelector).on("click", ".js-fix-with-change-quantity", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      const $btn = $(e.currentTarget);
      const lineGroupGuid = $btn.data("lineGroupGuid");
      const basketUniqueId = $btn.data("bid");
      const quantity = parseInt($btn.data("quantity"));
      const request: BasketUpdateLineRequestDto = {
        lineGroupGuid: lineGroupGuid,
        quantity: quantity,
        basketUniqueId: basketUniqueId,
      };
      this.changeBasketLineQuantity(undefined, request);
    });
  }

  protected getReloadQueryString(basketUniqueId: Guid): string | undefined {
    return undefined;
  }

  protected changeBasketLineQuantity(el?: HTMLElement, sendData?: BasketUpdateLineRequestDto) {
    let self = this;
    const $input = !!el ? $(el) : $(this.cssClassQuantityInput).filter(`[data-line-group-guid="${sendData?.lineGroupGuid}"]`);
    const quantityValue = $input.val() as string;
    const min = parseInt($input.prop("min")); // MOQ
    const step = parseInt($input.prop("step")); // IOQ

    const lineGroupGuid = $input.data("lineGroupGuid");
    const quantity = this.quantitySanitizer.sanitize(parseInt(quantityValue), min, step);
    // only necessary for basket template; for shopping basket, server will get basket from cookie :
    const basketUniqueId = $input.data("bid");

    if (typeof lineGroupGuid === "undefined") {
      return Promise.reject();
    }

    sendData ??= {
      lineGroupGuid: lineGroupGuid,
      quantity: quantity,
      basketUniqueId: basketUniqueId,
    };

    $input.prop("readonly", true);

    const posting = axios.post("/basket/update", sendData);

    posting.then((response: any) => {
      const data = response.data as BasketLineQuantityUpdateResponse;
      if (isChangeLineQuantityResponse(data)) {
        if ($input.parents(".nt-basketline").hasClass("table-warning") && data.updatedBasket.validationResult.isValid) {
          // all problems resolved
          // cannot access Continue button from this component, so reloading page
          location.reload();
        }
        const $component = $(this.elementSelector);
        data.updatedBasket.basketLines.forEach((basketLine) => {
          $component
            .find(this.cssClassQuantityInput)
            .filter(`[data-line-group-guid="${basketLine.basketLineGroupGuid}"]`)
            .val(basketLine.quantity)
            .data("prevQuantity", basketLine.quantity);
          const $line = $component.find(".nt-basketline").filter(`#${basketLine.basketLineGroupGuid}`);
          $line.find(".nt-total-line-amount").text(basketLine.totalLineAmountFormatted);
          $line.find(".nt-unit-price").text(basketLine.unitSalesPriceFormatted);
        });
      }
      if (isInsufficientStockDetectedDtoOfTypeBasketUpdateLineRequestDto(data)) {
        const createAddRequest = (
          encryptedProductId: string | undefined,
          quantity: number,
          basketUniqueId: Guid,
          dataLayerProduct: any
        ) => {
          return {
            encryptedProductId: encryptedProductId,
            quantity: quantity,
            dataLayerProduct: dataLayerProduct,
            basketUniqueId: basketUniqueId
          };
        };
        const createUpdateRequest = (
          encryptedProductId: string | undefined,
          quantity: number,
          basketUniqueId: Guid,
          dataLayerProduct: any,
          lineGroupGuid?: Guid
        ) => {
          return {
            quantity: quantity,
            dataLayerProduct: dataLayerProduct,
            basketUniqueId: basketUniqueId,
            lineGroupGuid: lineGroupGuid ?? ""
          };
        };

        function sendReplacementRequest(request: BasketAddRequestDto) {
          const posting = AddToBasketComponent.addToBasket(request);
          posting.then((response: any) => {
            const data: BasketAddResponseDto = response.data;
            if (isAddedToBasketDto(data)) {
              const $input = $(self.cssClassQuantityInput).filter(`[data-pid="${request?.encryptedProductId}"]`);
              const $line = $input.parents(".nt-basketline");
              $input.val(data.quantity);
              $line.find(".nt-total-line-amount").text(data.totalLineAmountFormatted);
            }
          });
          return posting;
        }

        function sendRemoveOriginalRequest(request: BasketUpdateLineRequestDto) {
          const $el = !!el ? $(el) : $(`[data-line-group-guid="${request.lineGroupGuid}"]`);
          const $removeBtn = $el.parents(".nt-basketline").find(".btn.nt-remove-basketline");
          const removeBtn = $removeBtn.get()[0];
          return self.removeBasketLine(removeBtn);
        }

        const openInsuffienctStockModal = OpenInsuffienctStockModalFactory.create<BasketUpdateLineRequestDto>(
          (data: BasketUpdateLineRequestDto) => this.changeBasketLineQuantity(undefined, data),
          sendReplacementRequest,
          sendRemoveOriginalRequest,
          createUpdateRequest,
          createAddRequest,
          () => {
            const $input = $(self.cssClassQuantityInput).filter(
              `[data-line-group-guid="${data.originalRequest.lineGroupGuid}"]`
            );
            $input.val($input.data("prevQuantity"));
          }
        );
        openInsuffienctStockModal(data);
      }
    });

    posting.catch(axiosX.defaultCatch);

    posting.catch((error) => {
      $input.val($input.data("prevQuantity"));
    });

    posting.finally(() => {
      $input.prop("readonly", false);
    });

    return posting;
  }

  protected removeBasketLine(el: HTMLElement) {
    const $this = $(el);
    const basketLineGroupGuid = $this.data("lineGroupGuid");
    const basketUniqueId = $this.data("bid");

    if (typeof basketLineGroupGuid === "undefined") {
      return Promise.reject();
    }

    const $line = $this.parents(".nt-basketline");
    $line.find("input").prop("readonly", true);
    $line.find(".btn").prop("disabled", true);

    const request: BasketLineRemoveRequestDto = {
      basketLineGroupGuid: basketLineGroupGuid,
      basketUniqueId: basketUniqueId,
    };

    const posting = axios.post("/basket/remove-line-group", request);

    posting.then((response: any) => {
      $line.remove();
      $(`#${basketLineGroupGuid}`).remove(); // in case removal triggered by unavailable product
    });

    posting.catch(axiosX.defaultCatch);

    posting.finally(() => {
      $line.find("input").prop("readonly", false);
      $line.find(".btn").prop("disabled", false);
    });

    return posting;
  }

  private handleBasketLineConflictResolved(pid: string, bid: Guid) {
    if ($(".nt-alert--unavailable-products").length === 0) return;
    const $alert = $(".nt-alert--unavailable-products").filter(`[data-bid="${bid}"]`);
    if ($alert.length === 0) return;
    $alert.children(`.nt-basketline[data-pid="${pid}"]`).remove();
    if ($alert.children(".nt-basketline").length === 0) {
      const qs = this.getReloadQueryString(bid);
      if (!qs) {
        location.reload();
      } else {
        window.location.href = window.location.pathname + "?" + qs;
      }
    }
  }
}
