import $ from "jquery";

export default class ToggleSubmit {
  static init() {
    $(".js-toggle-submit").on("click", (e: JQuery.ClickEvent) => {
      const box = $(e.currentTarget);
      var form = box.parents("form");
      if (box.is(":checked")) {
        form.find("[type=submit]").removeAttr("disabled");
        form.find("a.submit").removeAttr("disabled");
      } else {
        form.find("[type=submit]").attr("disabled", "disabled");
        form.find("a.submit").attr("disabled", "disabled");
      }
    });
  }
}
