import type {
  AddedToBasketDto,
  BasketAddRequestDto,
  BasketAddResponseDto,
  BasketLineQuantityUpdateResponse,
  BasketUpdateLineRequestDto,
  ChangeLineQuantityResponse,
  InsufficientStockDetectedDto,
} from "./types";

export function isAddedToBasketDto(response: BasketAddResponseDto): response is AddedToBasketDto {
  return (response as AddedToBasketDto).quantity !== undefined;
}

export function isInsufficientStockDetectedDtoOfTypeBasketAddRequestDto(
  response: BasketAddResponseDto
): response is InsufficientStockDetectedDto<BasketAddRequestDto> {
  return (response as InsufficientStockDetectedDto<BasketAddRequestDto>).originalRequest !== undefined;
}

export function isChangeLineQuantityResponse(
  response: BasketLineQuantityUpdateResponse
): response is ChangeLineQuantityResponse {
  return (response as ChangeLineQuantityResponse).quantity !== undefined;
}

export function isInsufficientStockDetectedDtoOfTypeBasketUpdateLineRequestDto(
  response: BasketLineQuantityUpdateResponse
): response is InsufficientStockDetectedDto<BasketUpdateLineRequestDto> {
  return (response as InsufficientStockDetectedDto<BasketUpdateLineRequestDto>).originalRequest !== undefined;
}
